import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as roomActions from '../actions/roomActions';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import copy from 'copy-to-clipboard';
import { Icon, Label, Menu, Table } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const tabs =
	[
		'media',
		'appearence',
		'advanced'
	];

const styles = (theme) =>
	({
		root :
			{
			},
		dialogPaper :
			{
				width                          : '30vw',
				[theme.breakpoints.down('lg')] :
					{
						width : '40vw'
					},
				[theme.breakpoints.down('md')] :
					{
						width : '50vw'
					},
				[theme.breakpoints.down('sm')] :
					{
						width : '70vw'
					},
				[theme.breakpoints.down('xs')] :
					{
						width : '90vw'
					}
			},
		tabsHeader :
			{
				flexGrow : 1
			},
		content	:
			{
				padding : '15px'
			}
	});

const PreviousRoomsContent = ({
	handleClosePreviousRooms,
	previousRoomsOpen,
	classes
}) =>
{
	const intl = useIntl();
	const [ previousRoomsArr, setPreviousRoomsArr ] = useState([]);

	useEffect(() =>
	{
		const myHeaders = new Headers();

		myHeaders.append('Content-Type', 'application/json');

		fetch('/GetPreviousRooms', {
			method  : 'GET',
			headers : myHeaders
		})
			.then((results) => results.json())
			.then((data) =>
			{
				if (data.length > 0)
					setPreviousRoomsArr(data);
			});
	}, []);

	return (
		<div className={classes.content}>
			<Table celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell><Typography>Name</Typography></Table.HeaderCell>
						<Table.HeaderCell><Typography>Date</Typography></Table.HeaderCell>
						<Table.HeaderCell><Typography>Actions</Typography></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{
						previousRoomsArr.length > 0 ?
							previousRoomsArr.map((object, index) =>
							{
								return (
									<Table.Row>
										<Table.Cell><Typography>{`${object.room_name.toLowerCase()} (${object.customer_name.toLowerCase()})`}</Typography></Table.Cell>
										<Table.Cell><Typography>{object.date}</Typography></Table.Cell>
										<Table.Cell>
											<Tooltip
												title={intl.formatMessage({
													id             : 'tooltip.copyPlayer',
													defaultMessage : 'Click to copy recording link'
												})}
											>
												<IconButton aria-label='Copy' onClick={() =>
												{
													copy(window.config.VIPEX_PLAYER_URL + object.url);
												}}
												>
													<FileCopyIcon />
												</IconButton>
											</Tooltip>
											<Tooltip
												title={intl.formatMessage({
													id             : 'tooltip.openPlayer',
													defaultMessage : 'Click to open player'
												})}
											>
												<IconButton aria-label='Open in tab' onClick={() =>
												{
													window.open(window.config.VIPEX_PLAYER_URL + object.url);
												}}
												>
													<LaunchIcon />
												</IconButton>
											</Tooltip>
										</Table.Cell>
									</Table.Row>
								);
							})
							:
							<Table.Row>
								<Table.Cell colspan={3}>
									<Typography>You have not joined any recorded rooms</Typography>
								</Table.Cell>
							</Table.Row>
					}
				</Table.Body>
			</Table>

		</div>
	);
};

PreviousRoomsContent.propTypes =
	{
		handleClosePreviousRooms :	PropTypes.func.isRequired,
		previousRoomsOpen        :	PropTypes.bool.isRequired,
		classes                  :	PropTypes.object.isRequired
	};

const mapStateToProps = (state) => ({
	previousRoomsOpen : state.room.previousRoomsOpen
});

const mapDispatchToProps = {
	handleClosePreviousRooms : roomActions.setPreviousRoomsOpen
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room.previousRoomsOpen === next.room.previousRoomsOpen
			);
		}
	}
)(withStyles(styles)(PreviousRoomsContent));
