/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CookieConsent from 'react-cookie-consent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logger from '../Logger';
import '../toaster.css';
import classnames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Auth0Lock from 'auth0-lock';
import { WebAuth } from 'auth0-js';
import Dialog from '@material-ui/core/Dialog';

const logger = new Logger('Login');

const styles = function(theme)
{
	return {
		root :
        {
        	display              : 'flex',
        	width                : '100%',
        	height               : '100%',
        	backgroundColor      : 'var(--background-color)',
        	backgroundImage      : `url(${window.config ? window.config.background : null})`,
        	backgroundAttachment : 'fixed',
        	backgroundPosition   : 'center',
        	backgroundSize       : 'cover',
        	backgroundRepeat     : 'no-repeat'
        }
	};
};
const LoginAuth0Lock = ({
	classes
}) =>
{
	const intl = useIntl();

	const authenticateWithConnection = (connectionName) =>
	{
		const params = Object.assign({
			domain   : window.config.VIPEX_AUTH_ENT_DOMAIN,
			clientID : window.config.VIPEX_AUTH_ENT_CLIENT
		});

		const webAuth = new WebAuth(params);

		webAuth.authorize({
			connection   : connectionName,
			responseType : 'token id_token',
			redirectUri  : `${window.location.origin }/auth-callback`
		}, function(err, authResult)
		{
			if (err)
			{
				console.log('NOT AUTHENTICATED');

				return alert(`Something went wrong: ${ err.message }. Please contact support`);
			}
			console.log('AUTHENTICATED');
			console.log(authResult);
		});
	};

	useEffect(() =>
	{
		const url = '/GetEnvProcess';

		fetch(url, {
			method : 'GET'
		})
			.then((response) => response.json())
			.then((data) =>
			{
				function addNewButtons()
				{
					const fragment = document.createDocumentFragment();

					fragment.appendChild(document.getElementById('icc-ad-btn'));
					document.getElementsByClassName('auth0-lock-social-buttons-container')[0].appendChild(fragment);
					document.getElementById('icc-ad-btn').style.display = 'block';
				}

				const authOptions = {
					allowShowPassword : true,
					container         : 'auth-container',
					allowSignUp       : false,
					theme             : {
						primaryColor        : '#123453',
						laveledSubmitButton : false,
						logo                : ''
					},
					languageDictionary : {
						title : 'Log In'
					},
					auth : {
						responseType : 'token'
					}
				};

				console.log(data);

				const lock = new Auth0Lock(data.VIPEX_AUTH_CLIENT, data.VIPEX_AUTH_DOMAIN, authOptions);

				lock.on('authenticated', function(authResult)
				{
					console.log('authenticated');
					console.log(authResult);
					fetch('/AuthenticateWithToken', {
						method  : 'POST',
						headers : {
							'Content-Type' : 'application/json'
						},
						body : JSON.stringify({
							accessToken : authResult.accessToken,
							type        : 'standard'
						})
					})
						.then((response) => response.json())
						.then((data) =>
						{
							if (data.success)
							{
								window.location = data.redirect;
							}
							else
							{
								setTimeout(() =>
								{
									window.location = data.redirect;
								}, 3000);
							}
						});
				});

				lock.on('signin ready', function()
				{
					if (data.VIPEX_AUTH_ENTERPRISE === '1')
					{
						setTimeout(() =>
						{
							var buttons = document.getElementsByClassName('auth0-lock-social-button-text');

							for (var i = 0; i < buttons.length; i++)
							{
								console.log(buttons[i].innerText);
								const in_text = buttons[i].innerText.replace('Sign in with', '');

								console.log(in_text);
								buttons[i].innerText = in_text;
							}

							document.getElementsByClassName('auth0-lock-name')[0].innerText = 'Log in with:';
							var buttons = document.getElementsByClassName('auth0-lock-social-button-text');

							for (var i = 0; i < buttons.length; i++)
							{
								console.log(buttons[i].innerText);
								const i_text = buttons[i].innerText.replace('Microsoft Account', 'Microsoft Live');

								console.log(i_text);
								buttons[i].innerText = i_text;
							}

							addNewButtons();

						}, 1500);
					}
				});

				lock.show();
			});
	}, []);

	return (
		<div className={classes.root}>
			<Dialog
				open
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<a style={{ display: 'none', pointer: 'cursor' }} title='Microsoft work or school account' className='auth0-lock-social-button auth0-lock-social-big-button' onClick={() => authenticateWithConnection('icc-ad')} data-provider='windowslive' type='button' id='icc-ad-btn'><div className='auth0-lock-social-button-icon' /><div className='auth0-lock-social-button-text'>Microsoft Work Account</div></a>

				<div id='auth-container' />
			</Dialog>
		</div>
	);
};

LoginAuth0Lock.propTypes =
{
	classes : PropTypes.object.isRequired
};

export default withStyles(styles)(LoginAuth0Lock);