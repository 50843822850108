import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../RoomContext';
import isElectron from 'is-electron';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import randomString from 'random-string';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CookieConsent from 'react-cookie-consent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client';
import Logger from '../Logger';
import '../toaster.css';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import classnames from 'classnames';
import { REACT_APP_TITLE } from 'react-native-dotenv';
import HelpIcon from '@material-ui/icons/Help';
import PreviousRooms from './PreviousRooms';
import PreviousRoomsContent from './PreviousRoomsContent';
import { Collapse } from 'react-collapse';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import MuiLink from '@material-ui/core/Link';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const logger = new Logger('ChooseRoom');
const styles = function(theme)
{
	return {
		root :
		{
			display              : 'flex',
			width                : '100%',
			height               : '100%',
			backgroundColor      : window.config.theme.backgroundColor ? window.config.theme.backgroundColor : 'var(--background-color)',
			backgroundImage      : `url(${window.config ? window.config.background : null})`,
			backgroundAttachment : 'fixed',
			backgroundPosition   : 'center',
			backgroundSize       : 'cover',
			backgroundRepeat     : 'no-repeat'
		},
		dialogTitle :
		{
		},
		dialogPaper :
		{
			width   : '40vw',
			padding : theme.spacing(2)
		},
		dialogPaperLong :
		{
			width   : '60vw',
			padding : theme.spacing(2)
		},
		logo :
		{
			display       : 'block',
			paddingBottom : '1vh'
		},
		loginButton :
		{
			position : 'absolute',
			right    : theme.spacing(2),
			top      : theme.spacing(2),
			padding  : window.config.theme.loginButtonPadding ? window.config.theme.loginButtonPadding : 0
		},
		largeIcon :
		{
			fontSize : '2em'
		},
		largeAvatar :
		{
			width  : 50,
			height : 50
		},
		green :
		{
			color : 'rgba(0, 153, 0, 1)'
		}
	};
};

const DialogTitle = withStyles(styles)((props) =>
{
	const { children, classes, ...other } = props;

	return (
		<MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
			{window.config.logo && <img alt='Logo' className={classes.logo} src={window.config.logo} />}
			<Typography variant='h5'>{children}</Typography>
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root :
	{
		padding : theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root :
	{
		margin  : 0,
		padding : theme.spacing(1)
	}
}))(MuiDialogActions);

function startTimer(duration, display)
{
	let timer = duration, minutes, seconds;

	setInterval(function()
	{
		if (display)
		{
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);

			minutes = minutes < 10 ? `0${ minutes}` : minutes;
			seconds = seconds < 10 ? `0${ seconds}` : seconds;

			display.textContent = `${minutes }:${ seconds}`;

			if (--timer < 0)
			{
				timer = duration;
			}
		}
	}, 1000);
}

const ChooseRoom = ({
	classes
}) =>
{
	const roomNameRegex = /[!#$%^&*(),.?":;{}\[\]£+~\/¬=|<>'#]/g;

	const [ roomId, setRoomId ] = useState(function()
	{
		const room_name = randomString({ length: 8 }).toLowerCase();
	});

	const [ customerName, setCustomerName ] = useState('');
	const [ realCustomerName, setRealCustomer ] = useState();
	const [ userName, setUserName ] = useState('');
	const [ ownLoggedIn, setLoggedIn ] = useState(false);

	useEffect(() =>
	{
		if (cookies.get('roomToJoin'))
		{
			var link = cookies.get('roomToJoin');
			cookies.remove('roomToJoin');
			window.location = link;
		}
	}, []);

	const loginBtnClick = () =>
	{
		logger.error(ownLoggedIn);
		if (ownLoggedIn)
		{
			// logged in
			fetch(window.config.VIPEX_AUTH_DOMAIN);
			fetch(window.config.VIPEX_AUTH_ENT_DOMAIN);
			fetch('/logout').then(() =>
			{
				window.location = '/';
			});
		}
		else
		if (window.config.VIPEX_AUTH_DOMAIN)
		{
			window.location = '/auth';
		}
		else
		{
			window.location = '/login';
		}
	};

	const timeSpan = useCallback((node) =>
	{
		if (node !== null)
		{
			if (global.params['start_time'])
			{
				const display = node;

				const duration = global.params['start_time'];

				let timer = duration, minutes, seconds;
				var tid = setInterval(function()
				{
					console.log(display);
					if (display)
					{
						minutes = parseInt(timer / 60, 10);
						seconds = parseInt(timer % 60, 10);

						minutes = minutes < 10 ? `0${ minutes}` : minutes;
						seconds = seconds < 10 ? `0${ seconds}` : seconds;

						display.textContent = `${minutes }:${ seconds}`;

						if (--timer < 0)
						{
							clearInterval(tid);
							window.location.href = `/${ document.getElementById('roomId').value}`;
						}
					}
				}, 1000);
			}
		}
	}, []);

	const intl = useIntl();

	const continueToJoin = () =>
	{
		const myHeaders = new Headers();

		myHeaders.append('Content-Type', 'application/json');

		fetch('/CheckRoomValid', {
			method : 'POST',
			body   : JSON.stringify({
				'access_token' : localStorage.getItem('vipex_live_access_token'),
				'roomId'       : roomId,
				'customerName' : customerName ? customerName : realCustomerName,
				'createRoom'   : false
			}),
			headers : myHeaders
		})
			.then((results) => results.json())
			.then((data) =>
			{
				if (data.success)
				{
					toast.success('Joining room');
					const url = `${roomId }@${ customerName ? customerName : realCustomerName}`;

					window.location.href = url;
				}
				else
				{
					toast.error(data.errorMessage ? data.errorMessage : 'The room you are trying to join is not available right now', {
						autoClose : data.autoClose ? data.autoClose : 5000
					});
				}
			});
	};

	const disableContinueButton = false;

	const [ open, setOpen ] = useState(false);
	const [ showRecordedRooms, setShowRecordedRooms ] = useState(false);

	useEffect(() =>
	{
		const openTimer = setTimeout(() => setOpen(true), 1000);
		const closeTimer = setTimeout(() => setOpen(false), 4000);

		return () =>
		{
			clearTimeout(openTimer);
			clearTimeout(closeTimer);
		};
	}, []);

	useEffect(() =>
	{
		const myHeaders = new Headers();

		myHeaders.append('Content-Type', 'application/json');

		fetch('/GetCustomerName', {
			method : 'POST',
			body   : JSON.stringify({
				'access_token' : localStorage.getItem('vipex_live_access_token')
			}),
			headers : myHeaders
		})
			.then((results) => results.json())
			.then((data) =>
			{
				logger.error(`Customer name is: ${ data._customer_name}`);
				logger.error(`User name is: ${ data._user_name}`);
				if (data._customer_name && data._user_name)
				{

					const regex = /[!#$%^&*(),.?":;{}\[\]£+~\/¬=|<>'#]/g;

					data._customer_name = data._customer_name.replace(regex, '');
					data._customer_name = data._customer_name.replace(/\s/g, '-');

					data._customer_name = data._customer_name.replace(';', '');

					setRoomId(data._user_name.replace(' ', '-'));
					setUserName(data._user_name.replace(' ', '-'));
					setCustomerName(data._customer_name);
					setRealCustomer(data._customer_name);
				}
			});

		fetch('/GetLoginStatus')
			.then((results) => results.json())
			.then((data) =>
			{
				setLoggedIn(data.loginStatus);
			});

	}, []);

	const handleTooltipClose = () =>
	{
		setOpen(false);
	};
	const handleTooltipOpen = () =>
	{
		setOpen(true);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open
				maxWidth='xl'
				classes={{
					paper : showRecordedRooms ? classes.dialogPaperLong : classes.dialogPaper
				}}
			>
				<ToastContainer
					position='bottom-right'
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<DialogTitle>
					<Grid container>
						<Grid container item xs={4} justify='flex-start'>
							{window.config.REACT_APP_TITLE}
						</Grid>
						<Grid container item xs={8} justify={'flex-end'}>
							<Button
								variant='contained'
								color='primary'
								onClick={() => { loginBtnClick(); }}
							>
								{ownLoggedIn ?
									'Logout'
									:
									'login'
								}
							</Button>
							&nbsp;
							{window.config.ENABLE_RECORDING == '1' &&
								<Button
									variant='contained'
									color='primary'
									onClick={() =>
									{
										setShowRecordedRooms(!showRecordedRooms);
									}}
								>
									Show Past Rooms
								</Button>
							}
						</Grid>
					</Grid>
					<hr />
				</DialogTitle>

				<DialogContent>
					<DialogContentText gutterBottom>
						<FormattedMessage
							id='room.chooseRoom'
							defaultMessage='Choose the name of the room you would like to join'
						/>
					</DialogContentText>
					<Grid container spacing={3}>
						<Grid container item xs={'6'} justify='flex-end'>
							<TextField
								id='roomId'
								label={intl.formatMessage({
									id             : 'label.roomName',
									defaultMessage : 'Room Name'
								})}
								variant='outlined'
								value={roomId || ''}
								margin='normal'
								inputProps={{ min: 0, style: { textAlign: 'right' } }}
								fullWidth
								onChange={(event) =>
								{
									const { value } = event.target;

									let new_val = value.replace(roomNameRegex, '');

									new_val = new_val.replace(' ', '-');
									setRoomId(new_val.toLowerCase());
								}}
								onBlur={() =>
								{
									if (roomId === '')
										setRoomId(randomString({ length: 8 }).toLowerCase());
								}}
							/>
						</Grid>
						<Grid item container xs={'6'} justify={'flex-start'}>
							<TextField
								id='customerId'
								label={intl.formatMessage({
									id             : 'label.companyName',
									defaultMessage : 'Company Name'
								})}
								placeholder={realCustomerName}
								variant='outlined'
								margin='normal'
								fullWidth
								InputProps={{
									startAdornment : <InputAdornment position='start'>@</InputAdornment>
								}}
								value={customerName != '' ? customerName : null}
								onChange={(event) =>
								{
									const { value } = event.target;

									let new_val = value.replace(roomNameRegex, '');

									new_val = new_val.replace(/\s/g, '-');
									setCustomerName(value);
								}}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item container xs={12} justify={'flex-end'} alignItems={'center'} direction={'row'}>
							<MuiLink href={window.config.REACT_APP_TERMS} target='_blank'><FormLabel>T&Cs</FormLabel></MuiLink>
							&nbsp;
							<Button
								onClick={(event) => continueToJoin(event)}
								// to={roomId + "@" + customer_name + (user_name ? "?display_name=" + user_name : "")}
								variant='contained'
								color='secondary'
								disabled={disableContinueButton}
							>
								<FormattedMessage
									id='label.chooseRoomButton'
									defaultMessage={ownLoggedIn ? 'Continue' : 'Continue as guest'}
								/>
							</Button>
						</Grid>
					</Grid>
				</DialogContent>

				{/* <DialogActions>*/}
				{/*	*/}
				{/* </DialogActions>*/}

				<Collapse isOpened={showRecordedRooms}>
					<Typography>Previously Recorded Rooms</Typography>
					<PreviousRoomsContent />
				</Collapse>

				{!isElectron() &&
					<CookieConsent buttonText={intl.formatMessage({
						id             : 'room.consentUnderstand',
						defaultMessage : 'I understand'
					})}
					>
						<FormattedMessage
							id='room.cookieConsent'
							defaultMessage='This website uses cookies to enhance the user experience'
						/>
					</CookieConsent>
				}
			</Dialog>
		</div>
	);
};

ChooseRoom.propTypes =
{
	classes : PropTypes.object.isRequired
};

export default withStyles(styles)(ChooseRoom);